import React, { useEffect, useCallback } from "react";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

const logo =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAYAAABXuSs3AAAABHNCSVQICAgIfAhkiAAABF1JREFUaEPtmV9WGjEUxm84vhd0AeIOcAXiCkoP9lnoBqwrcFxBcQOCz4XD7EBYgbgDXICOvtdJv5tkhnTKwISM2J5jzhkFJn9++XKTe5MIKjEFrX4T1Z3haeCpm6ongiiURDdB2H0uqznU6Z8AXEUtYzwMnpcYugv40L9FIm9wA32rVZYPqDLA5zBRF+/5d/wmPhtghh/4wpcAfg0FGUre438zzxzQgQ5g+3hY+UPkm/vAe4FrNelOknwRJOrrbBj5e8iPOSBh79+4IxsnX3ADQueA5s8rkzGriDMhv1fbXoWD1vUE5nFkhn62DlwBL8ocAx7lN0ulgLuotzAX+u/AA2h8gecD3NnYEnt1NJV/QfF+Hb2tArzQxNSTs//+4M5D9AFeQqziqvrbmco4alAcn5IU7M5LSOKKTmppRJgLPnoM0CY7MzshrhEhyt9kQRYOaBxV6VX2ES+2SqC1qhDn1K6l4UA++BN70Sx4Us+cYtGlr7XU02pwDX0L6AZJeiEhexRXJnbGsjpT2FTGUZ1iju9lZ9EhwLdrA2bR4KO0t/dUEU36Uittp5LtcGFwu+Ao+o4O/FA/VcQh+GaCfkZNqkBtogf82HhLaG53I3AlLuYAiQtYxIROdo8FDZ9C6I6NwGIYXMyCQ9V1cbhd38bg2jLm+LsPgQ+E9aXmqjZcPu9+jgBeK9pZL/DhY4+EOMNEheKjJ2zAkdq7ziHu1mOVxFxIXrKpPMNUPr0pOPsGTChj4+wfeKUIXExMLyLGzkmcs+IT/HSUzNaiQ64h9A5oZXSYTH4p4Yj2sDp4pIRVm4pZaqS8QcWsROFUCHz4OIBdnqpKMamgPE8w95QKAD9zslsVxvnMlbk4rixrwdmxxVJtjlXaQBxVznaQsG9q7wV6Qg6jFrwln0Qh4UCnQldFVpi14Onai9EkgTYgjqvqrLSA82GvTjTFXGwqylSNUdSBIlhuWHlWBwu9kJhQlYUXzXRoKbgK0tgvqEo6+FNXsHGMz+xAjEmq0VDnjMuTlA0DWzcZpqinlQj65xLI8cFrHKQ2ma0Sk8KOX5aCpw4tLaxV0sOtTVJ1RA6QIy+oslvWx3omRkle5K/dPETZZEVn/CpH8Tr9Sk9qiXZolppdupzRXI0E0T2cSf5Ks4N8OZPZ2enYfVlr49mO26rzOxMw5ZrLihfbBWcQy/vx6rAJNJfZPji3ymaYMTvXDrwPuCvlkvwf4EVFRFjLqwifHXod7m9dca943FKnFHDUd1D0asRsPtizvutprbn/UbdpgyLmAnA4H7Hv0tll9Xoqnl5ITQB+vA7c3INiYy4fcAfEnnPj5AXOrVoKrrwHMvc/dyjCwIVHKK9nJYCr22Q+3mBvFlyE3ctsY+Z2jq8K+c5zCrW5jFfyBteqpybDX2HDFKJiFQ5jJ86QBlROOS533msu6WIp4Aae1cQZ4V8Hl4BX96A9AAdeMluFSwNP6oT6bMO8W+GHVZ/5XAvmdfQ3oW5I5Uzz8CkAAAAASUVORK5CYII=";

let organization = JSON.parse(localStorage.getItem('organizationDetails'))

const documentDefinition = {
  pageSize: {
    width: 595,
    height: 250,
  },
  content: [
    {
      columns: [
        {
          image: 'logo',
          width: 35,
          margin: [0, 0, 0, 0],
          alignment: "center"
        },
        {
          width: '80%',
          stack: [
            {
              text: organization?.orgName,
              style: 'subHeaderTitle',
            },
            {
              text: organization?.orgEmail,
              style: 'normalText',
            },
            {
              text: organization?.orgAddress,
              style: 'normalText',
            },
          ],
          margin: [0, 0, 10, 0]
        }
      ],
      columnGap: 20
    },
    {
      table: {
        widths: ["100%"],
        body: [
          [
            {
              border: [false, true, false, false],
              text: "",
              alignment: "center",
              fontSize: 10,
            },
          ],
        ],
      },
      margin: [0, 10, 0, 10],
    },
  ],
  footer: function (currentPage, pageCount) {
    return {
      columns: [

        {
          width: '20%',
          alignment: 'left',
          stack: [
            {
              image: logo,
              width: 25,
              margin: [40, 0, 0, 2],
            },
          ],
          margin: [0, 0, 10, 0]
        },
        {
          width: '*',
          alignment: 'right',
          stack: [
            {
              text: 'PULSE EMR',
              style: 'normalText',
              margin: [40, 10, 0, 2],
            },
          ],
          margin: [0, 0, 10, 0]
        },
      ],
      margin: [0, 0, 10, 20]
    };
  },
  styles: {
    tableStyle: {
      fontSize: 9,
      alignment: "center",
    },
    tableHeader: {
      bold: true,
      fontSize: 9,
      color: "black",
      alignment: "center",
      fillColor: "#e5e5e5",
    },
    stackStyle: {
      border: "1px solid #cccccc",
    },
  },
  images: {
    logo: organization?.orgLogo,
  },
};

const RxPdf = () => {
  const createPdf = useCallback(() => {
    pdfMake.vfs = pdfFonts?.pdfMake?.vfs;
    pdfMake.createPdf(documentDefinition).download(`medicine-prescription.pdf`);
    setTimeout(function () {
      window.close();
    }, 1000);
  }, []);
  const executePrint = useCallback((pdfData) => {

    let mainBody = {
      table: {
        widths: ["auto", "*", "*", "*", "*", "*", "*", "*"],
      },
    };
    let table1Content = [];
    table1Content.push([
      { text: "S.No", style: "tableHeader" },
      { text: "Medicine", style: "tableHeader" },
      { text: "Form", style: "tableHeader" },
      { text: "Dose", style: "tableHeader" },
      { text: "Timing", style: "tableHeader" },
      { text: "Frequency", style: "tableHeader" },
      { text: "Duration", style: "tableHeader" },
      { text: "Qty", style: "tableHeader" },
    ]);

    pdfData.map((medicine, index) => {
      if (medicine.medicine) {
        table1Content.push([
          {
            text: (index + 1).toString(),
            style: "tableStyle",
          },
          {
            text: medicine.medicine,
            style: "tableStyle",
          },
          {
            text: medicine.form,
            style: "tableStyle",
          },
          {
            text: medicine.dose,
            style: "tableStyle",
          },
          {
            text: medicine.timing,
            style: "tableStyle",
          },
          {
            text: medicine.frequency,
            style: "tableStyle",
          },
          {
            text: medicine.duration,
            style: "tableStyle",
          },
          {
            text: medicine.qty,
            style: "tableStyle",
          },
        ]);        
      }
    });

    mainBody.table.body = table1Content;
    documentDefinition.content.push(mainBody);
    createPdf();
  }, []);
  useEffect(() => {
    let pdfData = sessionStorage.getItem("rx")
    let parsedData = JSON.parse(pdfData);
    executePrint(parsedData);
    sessionStorage.removeItem("rx");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executePrint]);
  return <div></div>;
};

export default RxPdf;