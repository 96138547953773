import React, { createRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import Sidebar from "../components/sidebar";
import Drawer from "../components/drawer";
import { useLocation, useNavigate } from "react-router-dom";
import { collection, deleteDoc, doc, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../services/fireBase/fireBaseServices";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Alert, Button, Snackbar, Stack } from "@mui/material";

const WithHeaderSidebar = (Component) => {
  return function Wrapper(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const token = sessionStorage.getItem('token');
    const notificationsCollection = collection(db, 'notifications');

    const [notificationData, setNotificationData] = useState([]);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [timeoutTime, setTimeoutTime] = useState(4000)

    useLayoutEffect(() => {
      if (location.pathname.includes("patient-history")) {
        setSidebarCollapsed(true);
      }
    }, [location.pathname]);

    const scrollDiv = useRef(createRef());

    const handleTop = () => {
      scrollDiv.current.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };

    const handleOpenAppointment = () => {
      navigate('/opd-appointment/manage');
    };

    const getAllNotifications = async () => {
      let tokenPayload = JSON.parse(atob(token?.split('.')[1]));
      try {
        const notificationsQuery = query(notificationsCollection, where("user_id", "==", tokenPayload?.userId));
        onSnapshot(notificationsQuery, (snapshot) => {
          const notifications = snapshot.docs.map((doc) => ({
            ...doc.data(),
            notificationId: doc.id,
          }));
          setNotificationData(notifications);
        });
      } catch (error) {
        console.log(error);
      }
    };

    const deleteAllNotifications = async (notificationId) => {
      const notifications = doc(notificationsCollection, notificationId);
      await deleteDoc(notifications);
    }

    useEffect(() => {
      getAllNotifications();
    }, []);

    useEffect(() => {
      if (notificationData.length !== 0) {
        setTimeout(() => {
          notificationData?.forEach((notification) => {
            deleteAllNotifications(notification.notificationId)
          });
        }, 5000);
      }
    }, [notificationData]);

    return (
      <div className="flex flex-row bg-white h-screen w-screen overflow-hidden">
        <div className="fixed bottom-4 right-4">
          <button className="bg-[#80509F] hover:bg-blue-600 text-white rounded-[50%] w-[30px] h-[30px] shadow-lg" onClick={handleTop}>
            <i className="fas fa-long-arrow-up"></i>
          </button>
        </div>
        <Sidebar
          onCollapseChange={(val) => {
            setSidebarCollapsed(val);
          }}
        />
        <Drawer
          open={sidebarCollapsed}
          setSidebarCollapsed={setSidebarCollapsed}
        />
        <div
          className={`h-screen bg-[#F7F8F9] overflow-auto ${sidebarCollapsed ? "w-full" : "w-[100%] md:w-[85%]"}`}
          ref={scrollDiv}
        >
          <Component {...props} />
          {
            notificationData.length !== 0 ? (
              // <Stack sx={{ width: '25%', position: "absolute", top: 5, right: 5 }} spacing={2}>
              //   {
              //     notificationData?.map((notification) => (
              //       <Alert severity="info" variant="filled" action={
              //         <Button color="inherit" size="small" onClick={() => handleOpenAppointment(notification.notificationId)}>
              //           View
              //         </Button>
              //       }>
              //         <div className="flex items-center gap-3">
              //           <div className="text-lg text-center border border-red-600 bg-red-600 text-white w-7 h-7 rounded-full">
              //             <p>{notificationData.length}</p>
              //           </div>
              //           <div>
              //             {notification.notification_message}
              //           </div>
              //         </div>
              //       </Alert>
              //     ))
              //   }
              // </Stack>
              <div className="absolute top-5 right-5">
                <div className="inline-flex rounded-lg border bg-blue-500 px-[18px] py-4 shadow-[0px_2px_10px_0px_rgba(0,0,0,0.08)] transition-all delay-200">
                  <p className="flex items-center text-sm font-medium text-white gap-3">
                    <span className="font-bold flex h-7 w-7 items-center justify-center rounded-full border-2 border-white">
                      {notificationData.length}
                    </span>
                    {notificationData[0]?.notification_message}
                    <div>
                      <button className="underline" onClick={() => handleOpenAppointment()}>View</button>
                    </div>
                  </p>
                </div>
              </div>
            ) : null
          }
        </div>
      </div>
    );
  };
};

export default WithHeaderSidebar;
