import React, { useCallback, useEffect, useState } from "react";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import Button from "../../components/button";
import TableWithSearchAndPagination from "../../components/tableWithSearchAndPagination";
import { useNavigate } from "react-router-dom";
import WithAuth from "../../layout/withAuth";
import {
  deleteOpdAppointmentById,
  getOpdAppointmentsListPage,
  getOpdAppointmentsListPageByDocId,
  updateDisplayOrder
} from "../../services/opdAppointment";
import dayjs from "dayjs";
import CheckPermission from "../../components/checkPermission";
import DangerBtn from "../../components/dangerBtn";
import SecondaryBtn from "../../components/secondaryBtn";
import { connect } from "react-redux";
import Modal from "../../components/modal";
import { handleDrawer } from "../../actions/userActions";
import Dropdown from "../../components/dropdown";
import { getAllDoctors } from "../../services/doctor";
import { collection, deleteDoc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../services/fireBase/fireBaseServices";


const headings = [
  {
    label: "Patient ID",
    width: "10%",
    align: "left",
  },
  {
    label: "Sequence",
    width: "15%",
    align: "left",
  },
  {
    label: "Patient Name",
    width: "20%",
    align: "left",
  },
  {
    label: "Priority",
    width: "15%",
    align: "left",
  },
  {
    label: "Appointment Date",
    width: "15%",
    align: "left",
  },
  {
    label: "Contact",
    width: "15%",
    align: "left",
  },
  {
    label: "Actions",
    width: "10%",
    align: "center",
  },
];

const dataToShow = [
  {
    key: "patientId",
    align: "left",
  },
  {
    key: "displayOrder",
    align: "left",
    type: "comboBox",
    listKey: "displayOrederList",
    comboBoxChange: (item, newVal, fn) => {
      let data = {
        oldDisplayOrder: item.displayOrder,
        newDisplayOrder: parseInt(newVal),
        appointmentDate: dayjs(new Date(item.orignalDate)).format("YYYY-MM-DD HH:mm:ss")
      }
      updateDisplayOrder(data).then(res => {
        if (res.status === 200) {
          fn();
        }
      });
    }
  },
  {
    key: "fullName",
    align: "left",
  },
  {
    key: "priority",
    align: "left",
  },
  {
    key: "appointmentDate",
    align: "left",
  },
  {
    key: "primaryContactNumber",
    align: "left",
  },
];

const OpdAppointment = ({ doctorId, handleDrawer }) => {
  const appointmentCollection = collection(db, 'appointments');


  const navigate = useNavigate();
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [callbackAttributes, setCallbackAttributes] = useState({
    searchSend: "",
    perPage: "25",
    selectedPage: "0",
    sort: "appointmentDate, asc",
    docId: null
  });
  const [appointmentList, setAppointmentList] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedAppointmentId, setSelectedAppoinemtnId] = useState(0);
  const [selectedAppointmentDoctorId, setSelectedAppoinemtnDoctorId] = useState(0);
  const [doctorList, setDoctorList] = useState([])
  const [appointmentsList, setAppointmentsList] = useState([])

  const openModal = (aptId) => {
    setSelectedAppoinemtnId(aptId);
    setModalVisible(true)
  };

  const closeModal = () => setModalVisible(false);

  const displayAppointmentList = useCallback(() => {
    setAppointmentList([]);
    const { searchSend, selectedPage, perPage, sort, docId } = callbackAttributes;
    let data = doctorId === 0 ? `searchKey=${searchSend}&page=${selectedPage}&size=${perPage}&sort=doctor,asc&sort=appointmentDate,asc&sort=displayOrder,asc` : `docId=${doctorId}&searchKey=${searchSend}&page=${selectedPage}&size=${perPage}`;
    if (docId > 0) {
      let newData = `docId=${docId}&searchKey=${searchSend}&page=${selectedPage}&size=${perPage}&sort=doctor,asc&sort=appointmentDate,asc&sort=displayOrder,asc`;
      getOpdAppointmentsListPageByDocId(newData).then((res) => {
        if (res?.status === 200) {
          const appointmentList = res?.result?.opdAppointmentListPage?.map(
            (item) => {
              return {
                ...item,
                priority: (
                  <p
                    className={`${item.priority === "Very Urgent"
                      ? "text-[#FF0000]"
                      : item.priority === "Normal"
                        ? "text-[#00ADEE]"
                        : "text-[#80509F]"
                      }`}
                  >
                    {item.priority}
                  </p>
                ),
                orignalDate: item.appointmentDate,
                appointmentDate: dayjs(item.appointmentDate).format("DD/MM/YYYY"),
              };
            }
          );
          setAppointmentList(appointmentList || []);
          setTotalData(res?.result?.getTotalRecords || 0);
          setTotalPages(res?.result?.getTotalPages || 0);
          return;
        }
      });
    }
    else if (doctorId === 0) {
      getOpdAppointmentsListPage(data).then((res) => {
        if (res?.status === 200) {
          const appointmentList = res?.result?.opdAppointmentListPage?.map(
            (item) => {
              return {
                ...item,
                priority: (
                  <p
                    className={`${item.priority === "Very Urgent"
                      ? "text-[#FF0000]"
                      : item.priority === "Normal"
                        ? "text-[#00ADEE]"
                        : "text-[#80509F]"
                      }`}
                  >
                    {item.priority}
                  </p>
                ),
                orignalDate: item.appointmentDate,
                appointmentDate: dayjs(item.appointmentDate).format("DD/MM/YYYY"),
              };
            }
          );
          setAppointmentList(appointmentList || []);
          setTotalData(res?.result?.getTotalRecords || 0);
          setTotalPages(res?.result?.getTotalPages || 0);
        } else {
          // props?.setSnackbar({
          //     showSnackbar: true,
          //     message: res?.message,
          //     variant: "error"
          // })
        }
      });
    }
    else {
      getOpdAppointmentsListPageByDocId(data).then((res) => {
        if (res?.status === 200) {
          const appointmentList = res?.result?.opdAppointmentListPage?.map(
            (item) => {
              return {
                ...item,
                priority: (
                  <p
                    className={`${item.priority === "Very Urgent"
                      ? "text-[#FF0000]"
                      : item.priority === "Normal"
                        ? "text-[#00ADEE]"
                        : "text-[#80509F]"
                      }`}
                  >
                    {item.priority}
                  </p>
                ),
                orignalDate: item.appointmentDate,
                appointmentDate: dayjs(item.appointmentDate).format("DD/MM/YYYY"),
              };
            }
          );
          setAppointmentList(appointmentList || []);
          setTotalData(res?.result?.getTotalRecords || 0);
          setTotalPages(res?.result?.getTotalPages || 0);
        } else {
          // props?.setSnackbar({
          //     showSnackbar: true,
          //     message: res?.message,
          //     variant: "error"
          // })
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackAttributes]);

  useEffect(() => {
    displayAppointmentList();
  }, [displayAppointmentList, callbackAttributes]);

  const handleDelete = (item) => {
    openModal(item.appointmentId);
    setSelectedAppoinemtnDoctorId(item.doctorId)
    // deleteOpdAppointmentById(item.appointmentId).then((res) => {
    //   if (res.status === 200) {
    //     displayAppointmentList();
    //   }
    // });
  };

  const handleDeleteAppointment = async () => {
    try {
      const appointmentQuery = query(appointmentCollection, where("doctor_id", "==", selectedAppointmentDoctorId));

      const querySnapshot = await getDocs(appointmentQuery);

      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (doc) => {
          const doctorData = doc.data();
          const updatedAppointments = doctorData?.appointments?.filter((appointment) => parseInt(appointment.appointment_id) !== parseInt(selectedAppointmentId));
          if (updatedAppointments.length > 0) {
            await updateDoc(doc.ref, { doctor_id: parseInt(doctorId), doctor_name: doctorData.doctor_name, appointments: updatedAppointments });
          } else {
            await deleteDoc(doc.ref);
          }
        });
      } else {
        console.log("No matching appointment found.");
      }
    } catch (error) {
      console.error("Error deleting appointment:", error);
    }
  }

  const handleEdit = (item) => {
    navigate("/opd-appointment/edit/" + item.appointmentId);
  };

  const handleGetAllDoctors = async () => {
    const res = await getAllDoctors()
    setDoctorList(res.result.doctorList)
  }

  const getAllAppointments = async () => {
    try {
      onSnapshot(appointmentCollection, (snapshot) => {
        const appointments = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            appointmentId: doc.id,
            appointments: data.appointments.slice(0, 5), // Limit to 5 appointments
          };
        });
        setAppointmentsList(appointments || []);
      });
    } catch (error) {
      console.error('Error fetching appointments:', error);
    }
  };


  useEffect(() => {
    handleGetAllDoctors()
    getAllAppointments()
  }, [])

  return (
    <div className="h-full">
      <Modal
        isVisible={isModalVisible}
        onClose={closeModal}
      >
        <div className="flex justify-center mb-4">
          <i className="far fa-times-circle fa-3x text-[#D42A46] "></i>
        </div>
        <p className="text-center mb-4">Are you sure, you want to delete OPD ?</p>
        <div className="flex justify-around">
          <DangerBtn
            text="DELETE"
            onClick={() => {
              if (selectedAppointmentId !== 0) {
                deleteOpdAppointmentById(selectedAppointmentId).then((res) => {
                  if (res.status === 200) {
                    closeModal();
                    displayAppointmentList();
                    handleDeleteAppointment()
                  }
                });
              } else {
                closeModal();
              }
            }}
          />
          <SecondaryBtn
            text="CANCEL"
            onClick={closeModal}
          />
        </div>
      </Modal>
      <div className="pl-8 py-7 flex items-center md:block gap-5">
        <button className="md:hidden" onClick={handleDrawer}>
          <i class="fa-solid fa-list"></i>
        </button>
        <p className="text-2xl leading-6 text-[#404040] font-bold">
          OPD Appointment
        </p>
      </div>

      <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
        <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
          <div className="flex items-center justify-start p-3 sm:p-7 border-b ">
            <div className="grow">
              <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
                OPD Appointment ( Registered )
              </p>
            </div>

            <div>
              <CheckPermission module="OPD Appointment" permission="Add">
                <Button
                  buttonStyle="mt-3 sm:mt-0"
                  text="New Appointment"
                  onClick={() => {
                    navigate("/opd-appointment/add");
                  }}
                />
              </CheckPermission>
            </div>
          </div>

          <div className="p-3 sm:p-7 sm:pt-5">
            <div className="grid md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 gap-5 mb-3">
              {
                appointmentsList?.map((item, index) => (
                  <div key={index} className="min-w-full px-5 py-2 rounded-lg border-2 border-solid border-[#ebeced] md:transition-all md:hover:-translate-y-1 md:hover:scale-110 md:duration-700 md:ease-in-out md:hover:shadow-md md:shadow-[#ebeced] cursor-pointer">
                    <h3 className="mb-4 text-black text-lg md:text-xl font-extrabold leading-none">
                      <span>{item?.doctor_name}</span>
                    </h3>
                    <ul className="mt-3">
                      <li className={`py-2 last:border-0 border-b-2 border-[#ebeced] border-solid`}>
                        <div
                          className="flex items-center text-black hover:text-black text-sm"
                        >
                          <div className="w-full font-semibold">Appointment no</div>
                          <div className="w-40 font-semibold">Patient name</div>
                        </div>
                      </li>
                      {
                        item?.appointments?.map((row, rowIndex) => (
                          <li key={rowIndex} className={`py-2 last:border-0 border-b-2 border-[#ebeced] border-solid`}>
                            {/*  ${rowIndex === item?.appointments?.length - 1 ? 'border-b-2 border-[#ebeced] border-solid' : 'border-b-2 border-[#ebeced] border-solid'} */}
                            <div
                              className="flex items-center text-black hover:text-black text-sm"
                            >
                              <div className="w-full">{row.appointment_id}</div>
                              <div className="w-40">{row.patient_name}</div>
                            </div>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                ))
              }

            </div>
            <TableWithSearchAndPagination
              headings={headings}
              dataToShow={dataToShow}
              data={appointmentList}
              totalData={totalData}
              totalPages={totalPages}
              actions={["delete", "edit"]}
              setCallbackAttributes={setCallbackAttributes}
              handleActionDelete={handleDelete}
              handleActionEdit={handleEdit}
              displayFunction={displayAppointmentList}
              module="OPD Appointment"
              actionPermission={{
                edit: "Edit",
                delete: "Delete",
              }}
            />
          </div>
        </div>
      </div>
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    doctorId: state.user.doctorId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleDrawer: () => dispatch(handleDrawer()),
  };
};

export default WithAuth(
  WithHeaderSidebar(connect(mapStateToProps, mapDispatchToProps)(OpdAppointment))
);
