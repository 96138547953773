import React, { useState, useEffect, useRef, createRef } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Autocomplete, Tab, Tabs, TextField } from "@mui/material";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import WithAuth from "../../layout/withAuth";
import { getOpdAppointmentById } from "../../services/opdAppointment";
import { getPatientById } from "../../services/patient";
import { getDoctorById } from "../../services/doctor";
import { createPatientOpdLifeCycle, getPatientHistory, checkEditablePatientOpdLifeCycle, updatePatientOpdLifeCycleById } from "../../services/patientOpdLifeCycle";
import { Accordion, ComplaintDiagnosisSection, FeesSection, IntervalHistorySection, PatientInfo, RxSection, SaveSection, SeenByHeader, VitalSection, SuccessModal, SpeechModel } from "./util"
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
// import { getOrganization } from "../../services/organization";
import Button from "../../components/button";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { addDisease, getDisease } from "../../services/disease";
import { db } from "../../services/fireBase/fireBaseServices";
import { collection, deleteDoc, getDocs, query, updateDoc, where } from "firebase/firestore";

const dayjs = require("dayjs").extend(require("dayjs/plugin/advancedFormat"))

const PatientHistory2 = ({ doctorId }) => {
    const appointmentCollection = collection(db, 'appointments');

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'], // Basic formatting options
            [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Ordered and bullet lists
            [{ 'header': [1, 2, 3, 4, 5, false] }],  // Dropdown for headers (default behavior){ 'font': [] }
            // ['clean']  // Clear formatting
        ]
        // toolbar: toolbarOptions, // Use the toolbar options here
    };
    const { id, opdId } = useParams();
    const navigate = useNavigate();
    const [speech, setSpeech] = useState({
        complaint: false,
        diagnosis: false,
        notes: false
    });
    const [polId, setPolId] = useState(0);
    const [fees, setFees] = useState(0);
    const [totalFee, setTotalFee] = useState(0);
    const [otherFees, setOtherFees] = useState([]);
    const [tabVal, setTabVal] = useState("patientInfo");
    const [patientInfo, setPatientInfo] = useState(null);
    const [doctorInfo, setDoctorInfo] = useState(null);
    const [patientHistory, setPatientHistory] = useState([]);
    const [vitals, setVitals] = useState({ temprature: "", height: "", weight: "", pulse: "", headCircum: "", spo2: "", bmi: "" });
    const [chiefComplaints, setChiefComplaints] = useState("");
    const [diagnosis, setDiagonosis] = useState("");
    const [notes, setNotes] = useState("");
    const [rx, setRx] = useState([
        { medicine: "", form: "Tablet", dose: "", timing: "Before Food", frequency: "Daily", duration: 0, qty: 0 },
        { medicine: "", form: "Tablet", dose: "", timing: "Before Food", frequency: "Daily", duration: 0, qty: 0 },
    ]);
    const [isModalVisible, setModalVisible] = useState(false);
    const [isSpeechModalVisible, setIsSpeechModalVisible] = useState(false);

    const { resetTranscript, transcript, browserSupportsSpeechRecognition } = useSpeechRecognition();
    const [intervalHistory, setIntervalHistory] = useState([
        { date: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"), doctorName: "", reason: "", note: "" },
    ]);
    const vitalRefs = useRef([createRef(), createRef(), createRef(), createRef(), createRef(), createRef(), createRef()]);
    const feesRef = useRef(createRef())
    const complainDiagnosisRef = useRef([createRef(), createRef()]);
    const [nextApointmentDate, setNextApointmentDate] = useState({ cbx: false, dateVal: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss") });

    const [textareaValue, setTextareaValue] = useState('');
    const [speechTextArea, setSpeechTextArea] = useState("");
    const [speechTextAreaStart, setSpeechTextAreaStart] = useState(false);
    const [diseaseData, setDiseaseData] = useState([]);
    const [selectedDisease, setSelectedDisease] = useState()
    const [inputValue, setInputValue] = useState('');
    const [diseaseError, setDiseaseError] = useState(false)
    const [speechLanguage, setSpeechLanguage] = useState('en-In')

    const openModal = () => {
        setModalVisible(true)
    };
    const closeModal = () => setModalVisible(false);

    const openSpeechModal = () => {
        setIsSpeechModalVisible(true)
    };
    const closeSpeechModal = () => {
        setIsSpeechModalVisible(false)
        setSpeechTextAreaStart(false)
    };

    const handleAddDisease = async () => {
        try {
            const newOption = await addDisease({ diseaseName: inputValue });
            if (newOption?.status === 201) {
                setDiseaseData((prev) => {
                    const data = [...prev]
                    data.push(newOption?.result?.disease)
                    return data
                })
                setSelectedDisease(newOption?.result?.disease?.id);
            }
        } catch (error) {
            console.error('Error adding option:', error);
        }
    };

    const deleteAppointment = async (doctorId, appointmentId, opdStatus) => {
        if (opdStatus === 1) {
            try {
                const appointmentQuery = query(appointmentCollection, where("doctor_id", "==", doctorId));

                const querySnapshot = await getDocs(appointmentQuery);

                if (!querySnapshot.empty) {
                    querySnapshot.forEach(async (doc) => {
                        const doctorData = doc.data();
                        const updatedAppointments = doctorData?.appointments?.filter((appointment) => parseInt(appointment.appointment_id) !== parseInt(appointmentId));
                        if (updatedAppointments.length > 0) {
                            await updateDoc(doc.ref, { doctor_id: parseInt(doctorId), doctor_name: doctorData.doctor_name, appointments: updatedAppointments });
                        } else {
                            await deleteDoc(doc.ref);
                        }
                    });
                } else {
                    console.log("No matching appointment found.");
                }
            } catch (error) {
                console.error("Error deleting appointment:", error);
            }
        }
    };

    const handleSave = (opdStatus) => {
        let validate = true;
        otherFees.forEach(e => {
            if (e.otherFeeLabel === "") {
                e.labelRef.current.focus();
                e.labelRef.current.style = "outline: 1px red solid"
                validate = false;
                return false;
            }
            if (e.otherFeeValue === "" || e.otherFeeValue === 0 || e.otherFeeValue === "0") {
                e.valueRef.current.focus();
                e.valueRef.current.style = "outline: 1px red solid"
                validate = false;
                return false;
            }
        })
        if (!validate)
            return;
        let otherFeesTemp = {};
        otherFees.forEach(e => {
            otherFeesTemp[e.otherFeeLabel] = e.otherFeeValue;
        })
        const payload = {
            patientId: id,
            opdId: opdId,
            doctorId: doctorId,
            diseaseId: selectedDisease,
            vitals: vitals,
            complaints: chiefComplaints,
            diagnosis: diagnosis,
            rx: rx,
            notes: notes,
            intervalHistory: intervalHistory,
            ix: "",
            opdStatus: opdStatus,
            fees: parseInt(fees),
            feesDetailsJson: { ...otherFeesTemp, totalFees: totalFee },
            dietPlan: textareaValue,
        }
        if (nextApointmentDate.cbx) {
            payload.nextVisitOn = nextApointmentDate.dateVal
        }
        if (typeof selectedDisease !== "undefined" && selectedDisease !== '' && selectedDisease !== null) {
            deleteAppointment(doctorId, opdId, opdStatus)
            setDiseaseError(false)
            if (polId === 0) {
                createPatientOpdLifeCycle(payload).then(res => {
                    if (res.status === 200) {
                        openModal();
                    }
                });
            } else {
                updatePatientOpdLifeCycleById(polId, { polId: polId, ...payload }).then(res => {
                    if (res.status === 200) {
                        openModal();
                    }
                })
            }
        } else {
            setDiseaseError(true)
        }
    }

    const handleChange = (event, newValue) => {
        setTabVal(newValue);
    };

    const calculateTotalFee = () => {
        let temp = parseFloat(fees);
        otherFees.forEach(element => {
            temp += parseFloat(element.otherFeeValue);
        });
        setTotalFee(isNaN(temp) ? 0 : temp);
    }

    useEffect(() => {
        checkEditablePatientOpdLifeCycle(id, opdId).then((res) => {
            if (res.status === 200) {
                let obj = res.result.patientHistory;
                if (obj.polId !== null) {
                    setPolId(parseInt(obj.polId))
                    if (obj.vitals !== null) {
                        setVitals(_ => ({ ...obj.vitals }));
                    }
                    if (obj.chiefComplaints !== null) {
                        setChiefComplaints(_ => obj.complaints);
                    }
                    if (obj.diagnosis !== null) {
                        setDiagonosis(_ => obj.diagnosis);
                    }
                    if (obj.rx !== null) {
                        setRx(_ => ([...obj.rx]));
                    }
                    if (obj.notes !== null) {
                        setNotes(_ => obj.notes);
                    }
                    if (obj.intervalHistory !== null) {
                        setIntervalHistory(_ => ([...obj.intervalHistory]));
                    }
                    if (obj.diseaseId !== null) {
                        setSelectedDisease(obj.diseaseId)
                    }
                }
            }
        });
        getPatientById(id).then((res) => {
            if (res.status === 200) {
                setPatientInfo((prev) => {
                    return {
                        ...prev,
                        ...res.result.patientDto,
                    };
                });
            }
        });
        getPatientHistory(id).then((res) => {
            if (res.status === 200) {
                setTextareaValue(res.result.patientHistory[0]?.dietPlan)
                setPatientHistory((prev) => {
                    prev = res.result.patientHistory
                    return [...prev];
                })
            }
        });
        getOpdAppointmentById(opdId).then((res) => {
            if (res.status === 200) {
                setFees(res.result.opdAppointmentResponseDto.fees);
                let k = Object.keys(res.result.opdAppointmentResponseDto.feesDetailsJson);
                k = k.filter((e) => (e !== "totalFees" && e !== "paymentMode" && e !== "transactionId"));
                let t = k.map((e, i) => {
                    return {
                        otherFeeLabel: e,
                        otherFeeValue: res.result.opdAppointmentResponseDto.feesDetailsJson[e],
                        labelRef: createRef(),
                        valueRef: createRef()
                    }
                })
                setOtherFees(t);
            }
        });
    }, [id, opdId]);

    useEffect(() => {
        getDoctorById(doctorId).then((res) => {
            if (res.status === 200) {
                setDoctorInfo((prev) => {
                    return {
                        ...prev,
                        ...res.result.doctorDetails,
                    };
                });
            }
        });
    }, [doctorId]);

    useEffect(() => {
        setTimeout(() => {
            document.querySelector('body').addEventListener("click", () => {
                SpeechRecognition.stopListening();
                resetTranscript();
                setSpeech(prev => {
                    prev.complaint = false;
                    prev.diagnosis = false;
                    prev.notes = false;
                    return prev;
                });
            })
        }, 1000);
        // getOrganization(1).then((res) => {
        //     if (res.status === 200) {
        //         const details = res.result.organizationDetails
        //         localStorage.setItem('organizationDetails', JSON.stringify(details))
        //     }
        // })
        getDisease().then((res) => {
            if (res.status === 200) {
                setDiseaseData(res?.result?.diseaseData)
            }
        })
    }, []);

    useEffect(() => {
        setSpeechTextArea(transcript)
    }, [transcript]);

    const handleSelectLanguage = (value) => {
        setSpeechLanguage(value)
        setTextareaValue("")
    }

    const handleGeneratePDF = async () => {
        try {
            const contentElement = document.getElementById("pdf");
            const qlContainerElement = contentElement.querySelector(".ql-container.ql-snow");
            const editorHtml = qlContainerElement.querySelector('.ql-editor')
            // const res = await getOrganization(1);

            localStorage.setItem("dietPlanPdf", JSON.stringify({ language: speechLanguage, data: editorHtml.innerHTML }));
            window.open("/dietPlanPdf");
            // if (res.status === 200) {
            //     const details = res.result.organizationDetails;
            //     localStorage.setItem("organizationDetails", JSON.stringify(details));
            // } else {
            //     console.error("Failed to fetch organization details");
            // }
        } catch (error) {
            console.error("Error generating the PDF data:", error);
        }
    };

    return (
        <div className="h-full text-[#404040]">
            <SuccessModal closeModal={closeModal} isModalVisible={isModalVisible} />
            <SpeechModel closeModal={closeSpeechModal} isModalVisible={isSpeechModalVisible} speechLanguage={speechLanguage} setSpeechLanguage={setSpeechLanguage} handleSelectLanguage={handleSelectLanguage} speechTextArea={speechTextArea} speechData={transcript} setTextareaValue={setTextareaValue} setSpeechTextAreaStart={setSpeechTextAreaStart} speechTextAreaStart={speechTextAreaStart} resetTranscript={resetTranscript} SpeechRecognition={SpeechRecognition} />

            <div className="mx-[60px] mt-7 mb-0">
                <p className="text-2xl leading-6 text-[#404040] font-bold">
                    <i className="far fa-long-arrow-left mr-4 cursor-pointer" onClick={() => { navigate("/opd-out/manage") }}></i>
                    Checkup
                </p>
            </div>
            <div className="bg-[#F7F8F9] py-6 px-[60px] h-[90vh]">
                <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
                    <PatientInfo patientInfo={patientInfo} />
                    <hr className="mx-[30px]" />
                    <SaveSection
                        nextApointmentDate={nextApointmentDate}
                        rx={rx}
                        setNextApointmentDate={setNextApointmentDate}
                        handleSave={handleSave}
                    />
                    <div className="border-b mx-2 sm:mx-5 pt-0 mt-0 sm:pt-0 flex justify-between items-center">
                        <Tabs
                            value={tabVal}
                            onChange={handleChange}
                            textColor="secondary"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                        >
                            <Tab
                                value={"patientInfo"}
                                label="Patient Information"
                                style={{ fontSize: 14, lineHeight: "20px", fontWeight: 500 }}
                            />
                            <Tab
                                value={"dietPlan"}
                                label="Diet Plan"
                                style={{ fontSize: 14, lineHeight: "20px", fontWeight: 500 }}
                            />
                            <Tab
                                value={"recentHistory"}
                                label="Old History"
                                style={{ fontSize: 14, lineHeight: "20px", fontWeight: 500 }}
                            />
                            <Tab
                                value={"otherDoctor"}
                                label="Transfer Summary"
                                style={{ fontSize: 14, lineHeight: "20px", fontWeight: 500 }}
                            />
                        </Tabs>
                    </div>
                    {
                        tabVal === "patientInfo" &&
                        <div className="h-[48vh] overflow-auto">
                            <div className="m-[30px] border-solid border-[#2E235E1A] border-[1px]">
                                <SeenByHeader doctorInfo={doctorInfo} />
                                <VitalSection vitalRefs={vitalRefs} vitals={vitals} setVitals={setVitals} />
                                <ComplaintDiagnosisSection
                                    complainDiagnosisRef={complainDiagnosisRef}
                                    chiefComplaints={chiefComplaints}
                                    setChiefComplaints={setChiefComplaints}
                                    diagnosis={diagnosis}
                                    setDiagonosis={setDiagonosis}
                                    speech={speech}
                                    setSpeech={setSpeech}
                                />
                                <div className="px-3 py-1">
                                    <Autocomplete
                                        size="small"
                                        disablePortal
                                        value={diseaseData?.find((item) => item.id === selectedDisease) || null}
                                        options={diseaseData || []}
                                        getOptionLabel={(option) => option.diseaseName}
                                        sx={{ width: 300 }}
                                        onChange={(_, newValue) => {
                                            if (newValue) {
                                                setDiseaseError(false)
                                                setSelectedDisease(newValue.id);
                                            } else {
                                                setDiseaseError(true)
                                                setSelectedDisease(null);
                                            }
                                        }}
                                        inputValue={inputValue}
                                        onInputChange={(_, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        noOptionsText={
                                            inputValue ? (
                                                <button onClick={handleAddDisease}>
                                                    Add disease "{inputValue}"
                                                </button>
                                            ) : (
                                                'No options'
                                            )
                                        }
                                        renderInput={(params) => <TextField {...params} label="Disease" error={diseaseError} />}
                                    />
                                    {
                                        diseaseError ? (
                                            <span className="text-red-600 font-semibold">
                                                Disease are required
                                            </span>
                                        ) : null
                                    }
                                </div>
                                <RxSection
                                    rx={rx}
                                    setRx={setRx}
                                    notes={notes}
                                    setNotes={setNotes}
                                    speech={speech}
                                    setSpeech={setSpeech}
                                />
                                <FeesSection
                                    fees={fees}
                                    setFees={setFees}
                                    calculateTotalFee={calculateTotalFee}
                                    otherFees={otherFees}
                                    setOtherFees={setOtherFees}
                                    totalFee={totalFee}
                                    feesRef={feesRef}
                                />
                            </div>
                            <div className="mx-[30px] border-solid border-[#2E235E1A] border-[1px]">
                                {
                                    patientHistory.length > 0 ?
                                        <Accordion
                                            doctorInfo={doctorInfo}
                                            patientHistory={patientHistory.filter((e, i) => (i <= 2))}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    }
                    {
                        tabVal === "dietPlan" &&
                        <div className="h-[60vh] overflow-auto">
                            <div className="flex justify-end items-center my-2 mr-5 gap-5">
                                <div>
                                    {browserSupportsSpeechRecognition && (
                                        <button onClick={openSpeechModal}>
                                            <i className="far fa-microphone mt-1 cursor-pointer text-xl"></i>
                                        </button>
                                    )}
                                </div>
                                <div>
                                    <Button
                                        text="Print"
                                        onClick={() => {
                                            handleGeneratePDF()
                                        }}
                                    />
                                </div>

                            </div>
                            <div className="px-4 py-2 bg-white rounded-b-lg">
                                <ReactQuill id="pdf" theme="snow" value={textareaValue} onChange={setTextareaValue} modules={modules} />
                            </div>
                        </div>
                    }
                    {
                        tabVal === "recentHistory" &&
                        <div className="h-[48vh] overflow-auto">
                            <div className="m-[30px] border-solid border-[#2E235E1A] border-[1px]">
                                {
                                    patientHistory.length > 0 ?
                                        <Accordion
                                            doctorInfo={doctorInfo}
                                            patientHistory={patientHistory}
                                        />
                                        : <p className="p-4">No Patient History</p>
                                }
                            </div>
                        </div>
                    }
                    {
                        tabVal === "otherDoctor" &&
                        <div className="h-[60vh] overflow-auto">
                            <IntervalHistorySection intervalHistory={intervalHistory} setIntervalHistory={setIntervalHistory} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        doctorId: state.user.doctorId,
    };
};

export default WithAuth(
    WithHeaderSidebar(connect(mapStateToProps, null)(PatientHistory2))
);
