import React, { createRef, useRef, useState, useEffect } from "react";
import Button from "../../../components/button";
import ImageUpload from "../../../components/imageUpload";
import TextInput from "../../../components/textInput";
import Dropdown from "../../../components/dropdown";
import TextArea from "../../../components/textArea";
import TimePicker from "../../../components/timePicker";
import DatePicker from "../../../components/datePicker";
import dayjs from "dayjs";
import { getAllDoctors } from "../../../services/doctor";

const priorityOptions = [
    {
        label: "Normal",
        value: "Normal",
    },
    {
        label: "Urgent",
        value: "Urgent",
    },
    {
        label: "Very Urgent",
        value: "Very Urgent",
    },
];

const validations = {
    appointmentDate: [
        {
            type: "required",
            message: "Please select appointment date",
        },
    ],
    appointmentTime: [
        {
            type: "required",
            message: "Please enter appointment time",
        },
    ],
    firstName: [
        {
            type: "required",
            message: "Please enter first name",
        },
    ],
    // middleName: [
    //   {
    //     type: "required",
    //     message: "Please enter middle name",
    //   },
    // ],
    lastName: [
        {
            type: "required",
            message: "Please enter last name",
        },
    ],
    primaryContactNumber: [
        {
            type: "required",
            message: "Please enter contact number",
        },
    ],
    priority: [
        {
            type: "required",
            message: "Please select priority.",
        },
    ],
    doctorId: [
        {
            type: "required",
            message: "Please select doctor",
        },
    ],
    fees: [
        {
            type: "required",
            message: "Please enter fees",
        }
    ],
    patientAge: [
        {
            type: "required",
            message: "Please enter age",
        },
    ]
};

const AppointmentInformation = (props) => {
    const [doctorList, setDoctorList] = useState([]);

    const [info, setInfo] = useState({
        appointmentId: "0",
        appointmentDate: new Date(),
        appointmentTime: "",
        patientId: "",
        liveConsultant: "",
        priority: "",
        firstName: "",
        lastName: "",
        middleName: "",
        primaryContactNumber: "",
        doctorId: "",
        profilePicture: "",
        // fees: "0"
    });
    useEffect(() => {
        if (props.info) {
            const appointmentInfo = props.info

            setInfo({
                appointmentId: appointmentInfo.hasOwnProperty("appointmentId") ? appointmentInfo.appointmentId : "0",
                appointmentDate: appointmentInfo.hasOwnProperty("appointmentDate") ? new Date(appointmentInfo?.appointmentDate) : new Date(),
                appointmentTime: appointmentInfo.hasOwnProperty("appointmentTime") ? appointmentInfo.appointmentTime : dayjs(new Date()).format('hh:mm:ss a').toUpperCase(),
                patientId: appointmentInfo.hasOwnProperty("patientId") ? appointmentInfo.patientId : "",
                liveConsultant: appointmentInfo.hasOwnProperty("liveConsultant") ? appointmentInfo.liveConsultant : "",
                priority: appointmentInfo.hasOwnProperty("priority") ? appointmentInfo.priority : "",
                firstName: appointmentInfo.hasOwnProperty("firstName") ? appointmentInfo.firstName : "",
                lastName: appointmentInfo.hasOwnProperty("lastName") ? appointmentInfo.lastName : "",
                middleName: appointmentInfo.hasOwnProperty("middleName") ? appointmentInfo.middleName : "",
                primaryContactNumber: appointmentInfo.hasOwnProperty("primaryContactNumber") ? appointmentInfo.primaryContactNumber : "",
                doctorId: appointmentInfo.hasOwnProperty("doctorId") && appointmentInfo.doctorId !== null ? appointmentInfo.doctorId : info.doctorId,
                profilePicture: appointmentInfo.hasOwnProperty("profilePicture") ? appointmentInfo.profilePicture : "",
                // age: appointmentInfo.hasOwnProperty("age") ? appointmentInfo.age : "",
                patientAge: appointmentInfo.hasOwnProperty("patientAge") || appointmentInfo.hasOwnProperty("age") ? appointmentInfo.patientAge || appointmentInfo.age : "",
                remarks: appointmentInfo.hasOwnProperty("remarks") ? appointmentInfo.remarks : "",
                prevDoctorId: appointmentInfo.hasOwnProperty("doctorId") && appointmentInfo.doctorId !== null ? appointmentInfo.doctorId : null
            })
        }
    }, [props.info])
    const inputRefs = useRef([
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
    ]);
    useEffect(() => {
        getAllDoctors().then((res) => {
            if (res.status === 200) {
                const doctorList1 = res?.result?.doctorList?.map((doctor) => {
                    return {
                        label: doctor.docName,
                        value: doctor.docId,
                    };
                });
                setDoctorList(doctorList1 || []);
                if (doctorList1.length === 1) {
                    setInfo((prev) => {
                        return {
                            ...prev,
                            doctorId: doctorList1[0].value
                        }
                    })
                }
            }
        });
    }, []);
    const handleChange = (name, value) => {
        setInfo((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };
    const handleNext = () => {
        let isValid = true;
        for (let i = 0; i < inputRefs.current.length; i++) {
            const valid = inputRefs.current[i].current.validate();
            if (!valid) {
                isValid = false;
            }
        }
        if (!isValid) {
            return;
        }
        props.saveInfo({
            ...info,
            appointmentDate: dayjs(info.appointmentDate).format('YYYY-MM-DD HH:mm:ss')
        })
        props.goToStep(2)
    }
    return (
        <div className="p-5 lg:px-[30px] lg:pb-9 lg:pt-6">
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
                <DatePicker
                    label="Appointment Date"
                    placeholderText="DD / MM / YYYY"
                    ref={inputRefs.current[0]}
                    name="appointmentDate"
                    onChange={handleChange}
                    value={info.appointmentDate}
                    validations={validations.appointmentDate}
                />
                <TextInput
                    label="Patient Age"
                    placeholder="Patient Age"
                    type="number"
                    value={info.patientAge}
                    ref={inputRefs.current[9]}
                    name="patientAge"
                    onChange={handleChange}
                    validations={validations.patientAge}
                />
                <TimePicker
                    label="Appointment Time"
                    placeholder="00 : 00 --"
                    value={info.appointmentTime}
                    name="appointmentTime"
                    onChange={handleChange}
                    ref={inputRefs.current[1]}
                    validations={validations.appointmentTime}
                />
                {/* <TextInput
                    label="Fees"
                    placeholder="Fees"
                    name="fees"
                    value={String(info.fees)}
                    onChange={handleChange}
                    ref={inputRefs.current[9]}
                    validations={validations.fees}
                /> */}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9 items-end">
                <TextInput
                    label="Patient Name"
                    placeholder="First Name"
                    value={info.firstName}
                    disabled={true}
                    ref={inputRefs.current[2]}
                    validations={validations.firstName}
                />

                <TextInput
                    placeholder="Middle Name"
                    value={info.middleName}
                    disabled={true}
                    ref={inputRefs.current[3]}
                />
                <TextInput
                    placeholder="Last Name"
                    value={info.lastName}
                    disabled={true}
                    ref={inputRefs.current[4]}
                    validations={validations.lastName}
                />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
                <div className="col-span-2">
                    <div className="flex flex-col md:flex-row md:gap-4 lg:gap-6 xl:gap-9">
                        <TextInput
                            label="Contact Number 1"
                            placeholder="+91 ----- -----"
                            value={info.primaryContactNumber}
                            disabled={true}
                            ref={inputRefs.current[5]}
                            validations={validations.primaryContactNumber}
                        />
                        <Dropdown
                            label="Consultant Doctor"
                            placeholder="Select Doctor"
                            options={doctorList}
                            value={doctorList.length === 1 ? doctorList[0].value : info.doctorId}
                            ref={inputRefs.current[6]}
                            onChange={(val) => {
                                handleChange("doctorId", val.value);
                            }}
                            validations={validations.doctorId}
                        />
                    </div>
                    <div className="flex flex-col md:flex-row md:gap-4 lg:gap-6 xl:gap-9">
                        <Dropdown
                            label="Priority"
                            placeholder="Select Priority"
                            options={priorityOptions}
                            value={info.priority}
                            ref={inputRefs.current[7]}
                            onChange={(val) => {
                                handleChange("priority", val.value);
                            }}
                            validations={validations.priority}
                        />
                        <TextInput
                            label="Live Consultant"
                            placeholder="Live Consultant"
                            name="liveConsultant"
                            value={info.liveConsultant}
                            onChange={handleChange}
                            ref={inputRefs.current[8]}
                        />
                    </div>
                    <TextArea label="Remarks" rows={3} name="remarks" onChange={handleChange}
                        value={info.remarks} />
                </div>
                <div className="flex flex-col">
                    <div>
                        <ImageUpload
                            // customStyle={{
                            //     mainDiv: 'justify-center',
                            //     webCam: 'w-full h-40',
                            //     webCamImg: 'w-40 h-40',
                            //     uploadImgMainDiv: 'flex justify-center',
                            //     uploadImg: 'w-40 h-40',
                            //     uploadImgDiv: 'w-40 h-40',
                            // }}
                            label="Patient's Photo"
                            image={info.profilePicture}
                            disabled={true}
                            userId={1}
                        />
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-end mt-9">
                <Button text="Save and Next" onClick={handleNext} />
            </div>
        </div>
    );
}

export default AppointmentInformation;
