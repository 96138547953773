import React, { useCallback, useEffect, useRef, useState } from "react";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import WithAuth from "../../layout/withAuth";
import { connect } from "react-redux";
import SearchBox from "../../components/searchbox";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { getDoctorOpd } from "../../services/doctor";
import CheckPermission from "../../components/checkPermission";
import Dropdown from "../../components/dropdown";
import ReactPaginate from "react-paginate";
import { handleDrawer } from "../../actions/userActions";

const headings = [
  {
    label: "Patient ID",
    width: "10%",
    align: "left",
  },
  {
    label: "Appointment No.",
    width: "15%",
    align: "left",
  },
  {
    label: "Patient Name",
    width: "20%",
    align: "left",
  },
  {
    label: "Priority",
    width: "15%",
    align: "left",
  },
  {
    label: "Last Visit Date",
    width: "15%",
    align: "left",
  },
  {
    label: "Contact",
    width: "15%",
    align: "left",
  },
  {
    label: "Actions",
    width: "10%",
    align: "center",
  },
];

const dataToShow = [
  {
    key: "patientId",
    align: "left",
  },
  {
    key: "appointmentId",
    align: "left"
  },
  {
    key: "fullName",
    align: "left",
  },
  {
    key: "priority",
    align: "left",
  },
  {
    key: "lastAppointmentDate",
    align: "left",
  },
  {
    key: "primaryContactNumber",
    align: "left",
  },
];

const TableWithSearchAndPagination = ({ headings, data, dataToShow, actions, module = "", actionPermission = {}, handleActionEdit = () => { }, handleActionDelete = () => { }, handleActionExport = () => { }, handleActionPrint = () => { }, handleActionAssign = () => { }, handleActionDownload = () => { }, handleActionRefresh = () => { }, handleActionReassign = () => { }, handleActionAdd = () => { }, handleActionInfo = () => { }, handleActionView = () => { }, handleActionReport = () => { }, handleActionServerList = () => { }, showChangePerPage = true, isPaginationRequired = true, showSearch = true, setCallbackAttributes = () => { }, totalData, tooltipLabels = {}, totalPages, }) => {
  const [perPage, setPerPage] = useState(25);
  const [selectedPage, setSelectedPage] = useState(0);
  const [sortBox, setSortBox] = useState([true]);
  const [currentSortIndex, setCurrentSortIndex] = useState(0);
  const [priority, setPriority] = useState("All");

  const handleChangeCallbackAttributes = (name, value) => {
    setCallbackAttributes((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleChangePagination = (event, value) => {
    setSelectedPage(value);
    handleChangeCallbackAttributes("selectedPage", value);
  };
  const handleChangePerPage = ({ label, value }) => {
    setSelectedPage(0);
    setPerPage(value);
    handleChangeCallbackAttributes("perPage", value);
  };
  const handleChangePriority = ({ _, value }) => {
    setSelectedPage(0);
    setPriority(value);
    handleChangeCallbackAttributes("priority", value);
  };
  const renderActionIcon = (icon, title, onClickAction, permission) => {
    return (
      <CheckPermission module={module} permission={permission}>
        <i className={`${icon} cursor-pointer hover:text-white`} onClick={onClickAction}></i>
      </CheckPermission>
    );
  };

  const renderTableActions = (item) => {
    return (
      <td>
        <div className="flex gap-3 justify-center  items-center">
          {actions?.includes("assign") || item?.actions?.includes("assign")
            ? renderActionIcon(
              "far fa-user-plus",
              tooltipLabels?.assign || "Assign",
              () => handleActionAssign(item)
            )
            : null}
          {actions?.includes("download") || item?.actions?.includes("download")
            ? renderActionIcon(
              "far fa-file-download",
              tooltipLabels?.download || "Download File",
              () => handleActionDownload(item)
            )
            : null}
          {actions?.includes("add") || item?.actions?.includes("add")
            ? renderActionIcon("far fa-plus", tooltipLabels?.add || "Add", () =>
              handleActionAdd(item)
            )
            : null}
          {actions?.includes("info") || item?.actions?.includes("info")
            ? renderActionIcon(
              "far fa-info-circle",
              tooltipLabels?.info || "Order Details",
              () => handleActionInfo(item)
            )
            : null}
          {actions?.includes("view") || item?.actions?.includes("view")
            ? renderActionIcon(
              "far fa-eye",
              tooltipLabels?.view || "View",
              () => handleActionView(item)
            )
            : null}
          {actions?.includes("report") || item?.actions?.includes("report")
            ? renderActionIcon(
              "far fa-chart-pie",
              tooltipLabels?.report || "Report",
              () => handleActionReport(item)
            )
            : null}
          {actions?.includes("edit") || item?.actions?.includes("edit")
            ? renderActionIcon(
              "fa-regular fa-pen-to-square text-[#80509F]",
              tooltipLabels?.edit || "Edit",
              () => handleActionEdit(item),
              actionPermission?.["edit"]
            )
            : null}
          {actions?.includes("print") || item?.actions?.includes("print")
            ? renderActionIcon(
              "far fa-print",
              tooltipLabels?.print || "Print",
              () => handleActionPrint(item)
            )
            : null}
          {actions?.includes("export") || item?.actions?.includes("export")
            ? renderActionIcon(
              "far fa-download",
              tooltipLabels?.export || "Export",
              () => handleActionExport(item)
            )
            : null}
          {actions?.includes("delete") || item?.actions?.includes("delete")
            ? renderActionIcon(
              "far fa-trash-alt text-[#FF0000]",
              tooltipLabels?.delete || "Delete",
              () => handleActionDelete(item),
              actionPermission?.["delete"]
            )
            : null}
          {actions?.includes("reassign") || item?.actions?.includes("reassign")
            ? renderActionIcon(
              "far fa-external-link-alt",
              tooltipLabels?.reassign || "Reassign",
              () => handleActionReassign(item)
            )
            : null}
          {actions?.includes("refresh") || item?.actions?.includes("refresh")
            ? renderActionIcon(
              "far fa-rotate-right",
              tooltipLabels?.refresh || "Refresh",
              () => handleActionRefresh(item)
            )
            : null}
          {actions?.includes("serverList") ||
            item?.actions?.includes("serverList")
            ? renderActionIcon(
              "far fa-server",
              tooltipLabels?.serverList || "Server List",
              () => handleActionServerList(item)
            )
            : null}
        </div>
      </td>
    );
  };

  const handleClickSort = (name, index) => {
    setCurrentSortIndex(index);
    if (sortBox[index] === true) {
      name += ",desc";
      const newSortBox = [...sortBox];
      newSortBox[index] = !newSortBox[index];
      setSortBox(newSortBox);
    } else {
      name += ",asc";
      const newSortBox = [
        ...sortBox?.map((value, arrayIndex) => {
          if (index !== arrayIndex) {
            return false;
          }
          return true;
        }),
      ];
      newSortBox[index] = true;
      setSortBox(newSortBox);
    }
    setSelectedPage(0);
    handleChangeCallbackAttributes("sort", name);
    handleChangeCallbackAttributes("selectedPage", 0);
  };

  return (
    <div className="w-full">
      <div className="flex flex-col gap-3 sm:flex-row justify-between sm:items-center mb-7">
        {showChangePerPage ? (
          <div className="flex items-center">
            <span className="text-sm text-[#404040] mr-2">Show</span>
            <Dropdown
              className="-mb-5"
              options={[
                { label: "25", value: 25 },
                { label: "50", value: 50 },
                { label: "75", value: 75 },
                { label: "100", value: 100 },
              ]}
              value={perPage}
              onChange={handleChangePerPage}
              indicatorContainerStyle="border-l-0 bg-white ml-1"
            />
            <span className="text-sm text-[#404040] ml-2">Entries</span>
          </div>
        ) : null}
        <div className="flex items-center">
          <span className="text-sm text-[#404040] mx-2">Priority</span>
          <Dropdown
            className="-mb-5 w-full"
            options={[
              { label: "All", value: "All" },
              { label: "Normal", value: "Normal" },
              { label: "Urgent", value: "Urgent" },
              { label: "Very Urgent", value: "Very Urgent" },
            ]}
            value={priority}
            adjustableWidth={true}
            onChange={handleChangePriority}
            indicatorContainerStyle="border-l-0 bg-white ml-1"
          />
        </div>
        {showSearch ? (
          <div className="flex flex-row items-center">
            <p className="text-sm leading-[21px] text-[#404040] mr-[13px]">
              Search
            </p>
            <SearchBox
              onChange={(name, value) => {
                handleChangeCallbackAttributes("searchSend", value);
              }}
            />
          </div>
        ) : null}
      </div>
      <div className="table-content-wrapper height-58 overflow-auto">
        <table striped="true" className="w-full">
          <thead>
            <tr>
              {headings?.map((heading, index) => {
                return (
                  <th
                    style={{ width: heading?.width }}
                    align={heading?.align}
                    key={index}
                  >
                    <span className="text-sm leading-[21px] rubik-bold">
                      {heading?.label}
                    </span>
                    {heading.sortKey ? (
                      <span
                        onClick={() => {
                          handleClickSort(heading?.sortKey, index);
                        }}
                      >
                        {index === currentSortIndex ? (
                          sortBox[index] === true ? (
                            <i className="fa-solid fa-sort-up ml-1 cursor-pointer"></i>
                          ) : (
                            <i className="fa-solid fa-sort-down ml-1 cursor-pointer"></i>
                          )
                        ) : (
                          <i className="fa-solid fa-sort ml-1 cursor-pointer"></i>
                        )}
                      </span>
                    ) : null}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((item, index) => {
                return (
                  <tr key={index} className="border-t border-[#CED4DA]">
                    {dataToShow &&
                      dataToShow?.map((data, index) => {
                        return (
                          <td align={data?.align} key={index}>
                            <span className="rubik">{item[data?.key]}</span>
                          </td>
                        );
                      })}
                    {actions?.length > 0 || item?.actions?.length > 0 ? (
                      renderTableActions(item)
                    ) : headings.filter((item) => item.label === "Actions")
                      .length ? (
                      <td></td>
                    ) : null}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={headings?.length}>No record found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isPaginationRequired ? (
        <div className="flex flex-col sm:flex-row gap-3 mt-5 items-center justify-between">
          <span className="text-sm leading-[21px] rubik">{`Showing ${data?.length ? perPage * selectedPage + 1 : 0
            } to ${perPage * selectedPage + 1 + data?.length - 1
            } of ${totalData} entries`}</span>
          <ReactPaginate
            nextLabel="Next"
            onPageChange={(page) => {
              handleChangePagination("", page.selected);
            }}
            pageRangeDisplayed={totalPages}
            pageCount={totalPages}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            className="flex flex-row items-center"
            pageLabelBuilder={(page) => {
              return (
                <div
                  className={`border ${page === 1 ? "border-l rounded-l-[5px]" : "border-l-0"
                    } py-1 px-[10px] border-[#CED4DA] ${page === totalPages ? "rounded-r-[5px]" : ""
                    }`}
                >
                  <span
                    className={`${selectedPage === page - 1
                      ? "text-white"
                      : "text-[#404040]"
                      } text-sm leading-[21px] rubik`}
                  >
                    {page}
                  </span>
                </div>
              );
            }}
            previousClassName={`text-sm leading-[21px] mr-[10px] ${selectedPage === 0 ? "text-[#C5C5C5]" : "text-[#404040]"
              }  rubik`}
            nextClassName={`text-sm leading-[21px] ml-[10px] ${selectedPage === totalPages - 1
              ? "text-[#C5C5C5]"
              : "text-[#404040]"
              }  rubik`}
            activeClassName={`bg-[#80509F] text-white ${selectedPage === 0 ? "rounded-l-[5px]" : ""
              } ${selectedPage === totalPages - 1 ? "rounded-r-[5px]" : ""}`}
            activeLinkClassName="text-white"
          />
        </div>
      ) : null}
    </div>
  );
};

const OpdOut = ({ doctorId, handleDrawer }) => {
  const navigate = useNavigate();
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [callbackAttributes, setCallbackAttributes] = useState({
    priority: "All",
    searchSend: "",
    perPage: "25",
    selectedPage: "0",
    sort: "appointmentId,asc",
  });
  const [appointmentList, setAppointmentList] = useState([]);
  const displayAppointmentList = useCallback((loader) => {
    setAppointmentList([]);
    const { priority, searchSend, selectedPage, perPage, sort } = callbackAttributes;
    let data = `priority=${priority}&searchKey=${searchSend}&page=${selectedPage}&size=${perPage}&sort=${sort}`;
    getDoctorOpd(doctorId, data, loader).then((res) => {
      if (res?.status === 200) {
        const appointmentList = res?.result?.opdAppointmentListPage?.map(
          (item) => {
            return {
              ...item,
              priority: (
                <p
                  className={`${item.priority === "Very Urgent"
                    ? "text-[#FF0000]"
                    : item.priority === "Normal"
                      ? "text-[#00ADEE]"
                      : "text-[#80509F]"
                    }`}
                >
                  {item.priority}
                </p>
              ),
              lastAppointmentDate: item.lastAppointmentDate !== null ? dayjs(item.lastAppointmentDate).format("DD/MM/YYYY") : null,
            };
          }
        );
        setAppointmentList(appointmentList || []);
        setTotalData(res?.result?.getTotalRecords || 0);
        setTotalPages(res?.result?.getTotalPages || 0);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackAttributes]);

  const exec = useRef(0);
  
  useEffect(() => {
    displayAppointmentList(true);
  }, [displayAppointmentList, callbackAttributes]);
  useEffect(() => {
    let displayInterval = setInterval(() => { displayAppointmentList(false) }, 30000);
    return () => {
      clearInterval(displayInterval);
    }
  }, [displayAppointmentList]);


  const handleEdit = (item) => {
    navigate("/patient-history/" + item.patientId + "/" + item.appointmentId);
  };

  return (
    <div className="h-full">
      <div className="pl-8 my-7 flex items-center md:block gap-5">
        <button className="md:hidden" onClick={handleDrawer}>
          <i class="fa-solid fa-list"></i>
        </button>
        <p className="text-2xl leading-6 text-[#404040] font-bold">
          OPD Appointment
        </p>
      </div>
      <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
        <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between p-3 sm:p-7 border-b ">
            <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
              OPD Appointment ( Last Two Days )
            </p>
          </div>
          <div className="p-3 sm:p-7 sm:pt-5">
            <TableWithSearchAndPagination
              headings={headings}
              dataToShow={dataToShow}
              data={appointmentList}
              totalData={totalData}
              totalPages={totalPages}
              actions={["edit"]}
              setCallbackAttributes={setCallbackAttributes}
              handleActionEdit={handleEdit}
              module="OPD Out"
              actionPermission={{
                edit: "Edit"
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    doctorId: state.user.doctorId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleDrawer: () => dispatch(handleDrawer()),
  };
};

export default WithAuth(
  WithHeaderSidebar(connect(mapStateToProps, mapDispatchToProps)(OpdOut))
);
